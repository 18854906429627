import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

console.log(
    "%c" +
        " ██████╗ ███████╗███╗   ██╗██╗██╗   ██╗███████╗\n" +
        "██╔════╝ ██╔════╝████╗  ██║██║██║   ██║██╔════╝\n" +
        "██║  ███╗█████╗  ██╔██╗ ██║██║██║   ██║███████╗\n" +
        "██║   ██║██╔══╝  ██║╚██╗██║██║██║   ██║╚════██║\n" +
        "╚██████╔╝███████╗██║ ╚████║██║╚██████╔╝███████║\n" +
        " ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚═╝ ╚═════╝ ╚══════╝\n" +
        "                                               ",
    "color: white;"
);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
